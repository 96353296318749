<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card  v-if="event && race">
      <Header :event="event"/>
      <v-card-title class="headline">
        Splits for <RaceSelector :event="event" :race="race" routeName="eventmanagerRaceSplits" />
      </v-card-title>

      <v-card-text>      
        Create and manage the splits for this race.
        <a v-if="tenant.isDefault" href="https://corporatefitness.helpscoutdocs.com/category/10-badges" target="_blank"><i class="fadl fa fa-books"/> Documentation</a>
      </v-card-text>
      
      <div v-if="badges && badges.length">
        <v-divider/>
        <div class="d-flex split--container">
          <div v-for="(item, idx) in badges" :key="idx" :style="getSplitStyle(item)" class="d-flex py-2 px-4" :title="item.name">
            <div class="d-flex grow" ></div>
            <v-icon v-if="item.activity_type === 'OTHER'" x-small>fa fa-exchange</v-icon>
            <v-icon v-else-if="item.activity_type">{{$helpers.getActivityIcon(item.activity_type)}}</v-icon>
            <div v-if="item.activity_type !== 'OTHER'" class=" ml-2 font-xs">
              <strong>{{ item.name }}</strong>
              <div v-if="item.value" class="">{{ item.value | distance(event.unit, /*ignoreUnit*/ false, [item.activity_type]) }}</div>
            </div>
            <div class="d-flex grow" ></div>
          </div>
          <div v-if="race.dist" :style="getFillerStyle()" class="d-flex"/>

        </div>    
        <v-divider/>
      </div>    

      <v-data-table
        :headers="headers"
        :items="badges"
        :items-per-page="100"
        hide-default-footer
      >
        <template v-slot:top>
          <v-toolbar flat color="white" class="mx-4">
            <v-toolbar-title class="ml-0 subtitle">Splits</v-toolbar-title>
            <v-spacer/>
      
            <v-btn color="accent" dark @click="addSplit"><v-icon small class="mr-2">fa-plus</v-icon> New Split</v-btn>
            <v-btn text color="accent" dark @click="addTransition"><v-icon small class="mr-2">fa-exchange</v-icon> New Transition</v-btn>
            <v-dialog v-model="showDialog" max-width="650px" @input="v => v || closeDialog()">
              <v-card>
                <v-card-title class="headline">{{ formTitle }}</v-card-title>
                <v-card-text>
                  <v-form v-model="valid" lazy-validation ref="badgeForm">
                
                      <v-text-field v-model="editedItem.name" label="Display name" required :rules="nameRules"></v-text-field>

                      <DistanceTextArea
                        v-model.number="editedItem.value" 
                        :unit="event.unit"
                        :mode="distanceMode"
                        :hint="distanceHint"
                        label="Split distance (distance for this split only, not the aggregate distance)" 
                        />

                      <v-input class="mt-4" style="flex-direction:column;">
                        <div>
                          <label class="text-muted">Duration in import file notation</label><br/>
                          <v-btn-toggle v-model="editedItem.unit" class="multiline-btns">
                            <v-btn value="PERCENTAGE" small>
                              <div>
                                <strong>Split Time</strong><br/>
                                Time of split
                              </div>
                            </v-btn>
                            <v-btn value="FIXED" small>
                              <div>
                                <strong>Clock Time</strong><br/>
                                Time from start
                              </div>
                            </v-btn>
                          </v-btn-toggle>
                        </div>
                      </v-input>

                      <v-select
                        :items="siteData.badge_fields"
                        v-model="editedItem.activity_field" 
                        item-value="type"
                        item-text="text"
                        label="Field"
                        class="mt-0"
                        />

                      <v-select
                        :items="activitiesWithDefault"
                        v-model="editedItem.activity_type" 
                        item-value="type"
                        item-text="text"
                        label="Activity Type"
                        class="mt-0"
                        />
            
                  </v-form>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="secondary" text @click="closeDialog">Cancel</v-btn>
                  <v-btn color="secondary"  :loading="$store.getters.isLoading" @click="saveDialog">Save</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            
          </v-toolbar>
        </template>
        <template v-slot:item.activity_type="{ item }">
          <v-icon v-if="item.activity_type">{{$helpers.getActivityIcon(item.activity_type)}}</v-icon>
        </template>
        <template v-slot:item.name="{ item }">
          <div>
            {{ item.name }} 
            <div class="text-muted">ID: {{ item.id }}</div>
          </div>
        </template>
        <template v-slot:item.value="{ item }">
            {{ item.value | distance(event.unit, /*ignore_unit*/false, [item.activity_type]) }} 
        </template>
        <template v-slot:item.unit="{ item }">
            {{ item.unit === 'FIXED' ? 'Clock time' : 'Split time' }} 
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small title="Edit" class="mr-4" @click="openDialog(item)">
            fa-pencil-alt
          </v-icon>
          <v-icon small title="Delete" class="mr-4" @click="deleteBadge(item)">
            fa-trash
          </v-icon>
        </template>
        <template v-slot:no-data>
          <i>No splits created yet, why not create one now?</i>
        </template>
      </v-data-table>
    </v-card>
  </div>      
</template>

<script>
import { mapGetters } from "vuex";
import eventManagerService from "@/services/eventManagerService";
import { EventBus } from '@/plugins/eventbus.js';
import siteData from '@/data/site.json'
import DateWithTimeZonePicker from "@/components/DateWithTimeZonePicker";
import DistanceTextArea from "@/components/DistanceTextArea";
import MarkdownEditor from "@/components/MarkdownEditor";
import Header from './_Header.vue'
import RaceSelector from './_RaceSelector.vue'
import DurationTextArea from "@/components/DurationTextArea";
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "Badges",
  components: {
    DateWithTimeZonePicker,
    DistanceTextArea,
    DurationTextArea,
    MarkdownEditor,
    Header,
    RaceSelector,
  },
  props: {
  },
  data() {
    return {
      tenant: tenant,
      event: null,
      race: null,
      badges: [],
      showDialog: false,
      copyFromDialog: false,
      initialPanel: 0,
      copyFromRaceId: null,
      valid: false,
      editedIndex: -1,
      editedItem: {active:true, color:'#F46524', metric: 'SPLIT', unit: 'FIXED'},
      defaultItem: {active:true, color:'#F46524', metric: 'SPLIT', unit: 'FIXED'},
      defaultTransition: {active:true, color:'#F46524', metric: 'SPLIT', unit: 'PERCENTAGE', activity_type: 'OTHER'},
      siteData: siteData,
      nameRules: [
        v => !!v || "Please enter the name",
      ],
      selectRules: [
        v => !!v || "Please select an item",
      ],
      headers: [
        { text: '', align: 'start', sortable: false, value: 'activity_type',},
        { text: 'Name', align: 'start', sortable: true, value: 'name',},
        { text: 'Split Distance', value: 'value', sortable: false },
        { text: 'Time format', value: 'unit', sortable: false },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
    };
  },
  async mounted() {
    await this.getProfile();
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
  },
  async beforeRouteUpdate (to, from, next) {
    // react to route changes...
    await this.getEvent(to.params.id, to.params.raceId);
    next();
  },
  methods: {
    async getEvent(id, raceId) {
      this.event = (await eventManagerService.get(id, /*includeSeries:*/ true)).data;
      this.race = this.event.races.find(r => r.id == raceId);
      this.seriesEvents = this.event.series_events;
      this.badges = (await eventManagerService.getBadges(id, raceId)).data.data.filter(x => x.metric === 'SPLIT');
    },

    async getProfile() {
      if (this.user) {
        await this.getEvent(this.$route.params.id, this.$route.params.raceId);
      }
    },

    getSplitStyle(badge) {
      //var totalDistance = this.badges.reduce((accumulator, x) => accumulator + x.activity_distance, 0);
      var color = '#E0E0E0';
      if (badge.activity_type === 'SWIMMING') {
        color = '#BBDEFB'; // blue
      }
      if (badge.activity_type === 'CYCLING') {
        color = '#B2DFDB'; // teal
      }
      if (badge.activity_type === 'RUNNING') {
        color = '#F0F4C3'; // lime
      }
      if (badge.activity_type === 'OTHER') {
        color = '#CFD8DC';
      }
      return {
        backgroundColor: color,
        flex: badge.value,// / totalDistance,
        placeItems: 'center',// / totalDistance,
      };
    },
    getFillerStyle(badge) {
      if (!this.race.dist) {
        return {};
      }
      var totalSplitDistance = this.badges.reduce((accumulator, x) => accumulator + (x.value || 0), 0);
      return {
        backgroundColor: '#616161',
        flex: this.race.dist - totalSplitDistance,
        placeItems: 'center',// / totalDistance,
      };
    },

    addSplit() {
      this.editedIndex = -1;
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedItem.rank = this.badges.length;
      if (this.race.activity_types && this.race.activity_types.length == 1 && this.race.activity_types[0] != 'MULTI_SPORT') {
        this.editedItem.activity_type = this.race.activity_types[0];
      }
      this.showDialog = true
    },
    async addTransition() {
      this.editedIndex = -1;
      this.editedItem = Object.assign({}, this.defaultTransition);
      this.editedItem.rank = this.badges.length;
      this.editedItem.name = 'Transition ' + (this.badges.filter(x => x.activity_type == 'OTHER').length + 1);
      await this.saveBadge();
    },
    
    openDialog(item) {
      this.editedIndex = this.badges.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.showDialog = true
    },

    async deleteBadge (item) {
      if (confirm(`Are you sure you want to delete thie badge ${item.name}?`)) {
        await eventManagerService.deleteBadge(this.event.id, this.race.id, item.id);
        await this.getProfile();
      }
    },

    async copyBadges() {
      if (this.copyFromRaceId) {
        const response = await eventManagerService.copyBadges(this.event.id, this.copyFromRaceId, this.race.id, 'SPLIT');
        if (response.data.status == "OK") {
          this.copyFromDialog = false;
          await this.getProfile();
        }
      }
    },

    closeDialog () {
      this.showDialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.previewUrl = null;
      })
    },

    async saveDialog() {
      this.$refs.badgeForm.validate();
      this.$nextTick(async () => {
        if (this.valid) {
          var success = await this.saveBadge();
          if (success) this.closeDialog();
        }
      });
    },
    async saveBadge() {
      console.log('Saving', this.editedItem);
      var response = await eventManagerService.putBadge(this.event.id, this.race.id, this.editedItem);
      if (response.data.status == 'OK') {
        this.$helpers.toastResponse(this, response.data, 'Split saved successfully.')
        await this.getProfile();
        return true;
      }
      return false;
    },

  },
  computed: {
    breadcrumbItems() {
      return !this.event || !this.race ? [] : [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: this.event.name, exact: true, to: { name: 'eventmanagerView', params: {id: this.event.id}} },
        { text: this.race.name, exact: true, to: { name: 'eventmanagerRace', params: {id: this.event.id, raceId: this.race.id}} },
        { text: 'Splits', disabled: true },
      ];
    },
    activitiesWithDefault(){
      var data = siteData.activities;
      data.unshift({type: null, text: '- Any -'});
      return data;
    },
    formTitle() {
      return this.editedIndex === -1 ? 'New Split' : 'Edit Split'
    },
    distanceMode() {
      return this.editedItem && this.editedItem.activity_type === 'STAIR_CLIMBING' 
          ? this.$helpers.UnitType.NUMBER
          : this.$helpers.UnitType.DISTANCE;
    },
    distanceHint() {
      return this.editedItem && this.editedItem.activity_type === 'STAIR_CLIMBING' 
          ? 'Number of steps'
          : `Distance (in ${this.event.unit === 'METRIC' ? 'km' : 'mi' })`;
    },

    ...mapGetters({
      user: "user"
    })
  },
};
</script>
<style lang="scss">
  .split--container {
    .d-flex + .d-flex { border-left: solid 1px #eee;}
    .d-flex { border-right: solid 1px #aaa;}
    .d-flex:last-child { border-right-style: none;}
  }
</style>

